import { module } from 'modujs';
import {lerp} from "../../utils/maths";
import {transform} from "../../utils/transform";
import delegate from 'delegate';

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.lastX = 0;
        this.lastY = 0;
        this.currentX = 0;
        this.currentY = 0;
        this.isOverLink = false;

        // FUNC
        this.animate = this.animate.bind(this);
    }

    init() {

        /**
         * EVENTS
         */
        document.addEventListener('mousemove', (event) => {
            this.currentX = event.clientX;
            this.currentY = event.clientY;
        });

        /**
         * DELEGATE
         */
        delegate('a', 'mouseover', (event) => {
            if(event.delegateTarget.classList.contains('filter'))
                this.el.classList.add('filter');
            else if(event.delegateTarget.classList.contains('email'))
                this.el.classList.add('email');
            else if(event.delegateTarget.classList.contains('arrow-down'))
                this.el.classList.add('arrow-down');
            else if(event.delegateTarget.classList.contains('linkedin'))
                this.el.classList.add('linkedin');
            else if(event.delegateTarget.classList.contains('cross'))
                this.el.classList.add('cross');
            else if(event.delegateTarget.classList.contains('download'))
                this.el.classList.add('download');
            else
                this.el.classList.add('arrow-right');

            this.isOverLink = true;
        }, false);
        delegate('a', 'mouseout', (event) => {
            if(event.delegateTarget.classList.contains('filter'))
                this.el.classList.remove('filter');
            else if(event.delegateTarget.classList.contains('email'))
                this.el.classList.remove('email');
            else if(event.delegateTarget.classList.contains('arrow-down'))
                this.el.classList.remove('arrow-down');
            else if(event.delegateTarget.classList.contains('linkedin'))
                this.el.classList.remove('linkedin');
            else if(event.delegateTarget.classList.contains('cross'))
                this.el.classList.remove('cross');
            else if(event.delegateTarget.classList.contains('download'))
                this.el.classList.remove('download');
            else
                this.el.classList.remove('arrow-right');

            this.isOverLink = false;
        }, false);

        /**
         * ANIMATE
         */
        window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        this.animate();
    }

    /**
     * Animate
     */
    animate() {
        const _scale = this.isOverLink ? 1 : 0.3;
        this.lastX = lerp(this.lastX, this.currentX, 0.4);
        this.lastY = lerp(this.lastY, this.currentY, 0.4);
        transform(this.el, `translate3d(${this.lastX - 40}px, ${this.lastY - 40}px, 0)`);
        transform(this.$('circle')[0], `translate3d(0, 0, 0) scale(${_scale})`);

        requestAnimationFrame(this.animate);
    }

    /**
     * RESET
     */
    reset() {
        this.el.className = "";
        this.isOverLink = false;
    }
}
