import { module } from 'modujs';
import { html } from './../../utils/environment';
import {StoreSingleton} from "../../singletons/store";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.threshold = this.getData('threshold');
        this.margin = this.getData('margin');

        if(this.threshold === null) {
            this.threshold = 0;
        }

        if(this.margin === null) {
            this.margin = 50;
        }

        this.observer = null;
        this.options = {
            root: null,
            rootMargin: `0px 0px -${this.margin}px 0px`,
            threshold: this.threshold
        }

        // FUNC
        this._callback = this._callback.bind(this);
    }

    /**
     * Init
     */
    init() {
        this.observer = new IntersectionObserver(this._callback, this.options);
        this.$('item').forEach(item => {
            /*if(this._isInViewport(item) === true)
                item.classList.add('is-inview')
            else
                this.observer.observe(item);*/
            this.observer.observe(item);
        });
    }

    /**
     * Observe
     */
    destroy() {
        this.observer.disconnect();
    }

    /**
     * Callback
     * @param i__entries
     * @param i__observer
     * @private
     */
    _callback(i__entries, i__observer) {
        /*if(html.classList.contains('has-dom-ready')) {

        }*/
        i__entries.forEach(entry => {
            if(entry.isIntersecting || entry.intersectionRatio > 0) {
                entry.target.classList.add('is-inview');
                this.observer.unobserve(entry.target);
            }
        });
    }

    _isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}
