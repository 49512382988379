import { module } from 'modujs';
import {transform} from "../../utils/transform";
import gsap from "gsap";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.currentX = 0;
        this.currentY = 0;

        // EVENTS
        this.events = {
            'mouseenter': 'show',
            'mouseleave': 'hide',
            'mousemove': 'move'
        }
    }

    init() {
    }

    move(event) {
        if(this.$('container')[0])
            transform(this.$('container')[0], `translate3d(${event.clientX - this.$('container')[0].clientWidth}px, ${event.clientY - (this.$('container')[0].clientWidth / 2)}px, 0)`);
    }

    show() {
        if(this.$('img')[0]) {
            gsap.to(this.$('img')[0], {
                delay: 0.05,
                duration: 0.6,
                alpha: 1,
                ease: 'power4.out',
                overwrite: true
            });
        }
    }

    hide() {
        if(this.$('img')[0]) {
            gsap.to(this.$('img')[0], {
                duration: 0.3,
                alpha: 0,
                ease: 'power4.out',
                overwrite: true
            });
        }
    }
}
