import { module } from 'modujs';
import { panel } from "../../utils/environment";

export default class extends module {
    constructor(m) {
        super(m);

        // FUNC
        this._changeColor = this._changeColor.bind(this);
    }

    /**
     * Init
     */
    init() {
        this.el.addEventListener('click', this._changeColor);
    }

    /**
     * Destroy
     */
    destroy() {
        this.el.removeEventListener('click', this._changeColor);
    }

    /**
     * Change color
     * @private
     */
    _changeColor() {
        panel.style.backgroundColor = this.getData('hex');
    }
}
