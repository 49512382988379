import { module } from 'modujs';
import {transform} from "../../utils/transform";
import gsap from "gsap";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.currentX = 0;
        this.currentY = 0;

        // EVENTS
        this.events = {
            'mouseenter': 'show',
            'mouseleave': 'hide',
            'mousemove': 'move'
        }
    }

    init() {}

    move(event) {
        console.log(event.clientX, event.clientY);
        if(this.$('container')[0])
            transform(this.$('container')[0], `translate3d(${event.clientX - (this.$('container')[0].clientWidth * 0.7)}px, ${event.clientY - (this.$('container')[0].clientHeight * 0.66)}px, 0)`);
    }

    show() {
        if(this.$('img')[0]) {
            gsap.to(this.$('img')[0], {
                duration: 0.6,
                alpha: 1,
                ease: 'power4.out',
                overwrite: true
            });
        }

        const _tl = gsap.timeline({
            defaults: {overwrite: true}
        });
        _tl.to(this.el.querySelector('.over-title'), {
            duration: 0.4,
            alpha: 1,
            ease: 'power4.out'
        })
        _tl.to(this.el.querySelectorAll('.overflow-wrapper'), {
            duration: 0.8,
            alpha: 1,
            y: '0%',
            rotation: 0.01,
            stagger: 0.025,
            ease: 'power4.out',
            overwrite: true
        }, 0.3);
    }

    hide() {
        if(this.$('img')[0]) {
            gsap.to(this.$('img')[0], {
                duration: 0.3,
                alpha: 0,
                ease: 'power4.out',
                overwrite: true
            });
        }

        const _tl = gsap.timeline({
            defaults: {overwrite: true}
        });
        _tl.to(this.el.querySelectorAll('.overflow-wrapper'), {
            duration: 0.4,
            alpha: 0,
            y: '100%',
            rotation: 0.01,
            stagger: 0.025,
            ease: 'power4.in'
        })
        _tl.to(this.el.querySelector('.over-title'), {
            duration: 0.4,
            alpha: 0,
            ease: 'power4.out'
        }, 0.4)
    }
}
