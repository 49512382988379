import { module } from 'modujs';
import { html } from '../../utils/environment';
import gsap from "gsap";
import {findValueByPrefix} from "../../utils/utils";

export default class extends module {
    constructor(m) {
        super(m);

        // FUNC
        this.siteIsReady = this.siteIsReady.bind(this);
    }

    init() {
        this.on('siteIsReady', 'Home', this.siteIsReady);
        this.on('siteIsReady', 'Creations', this.siteIsReady);
        this.on('siteIsReady', 'Single', this.siteIsReady);
        this.on('siteIsReady', 'About', this.siteIsReady);
        this.on('siteIsReady', 'Skills', this.siteIsReady);
        this.on('siteIsReady', 'Life', this.siteIsReady);
        this.on('siteIsReady', 'Common', this.siteIsReady);
    }

    siteIsReady() {
        const _tl = gsap.timeline({
            onComplete: () => {
                this.startSite();
            }
        });
        _tl.to('#preload-logo-1', {
            duration: 0.8,
            alpha: 0,
            y: -20,
            ease: 'power4.in'
        }, 0);
        _tl.to('#preload-logo-2', {
            duration: 0.8,
            alpha: 0,
            y: -20,
            ease: 'power4.in'
        }, 0.1);
        _tl.to(this.$('panel'), {
            duration: 1.4,
            scaleX: 0,
            ease: 'power4.inOut'
        }, 0.2);
    }

    startSite() {
        document.querySelector('body').removeChild(document.querySelector('#preload'));

        setTimeout(() => {
            html.classList.add('is-loaded', 'is-ready', 'has-dom-ready');
            const _modules = document.querySelector('[data-page]').dataset;
            const _pageClass = findValueByPrefix(_modules, 'module');
            this.call('start', {}, _pageClass)
        }, 50);
    }
}
