import { module } from 'modujs';
import gsap from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export default class extends module {
    constructor(m) {
        super(m);
        
        // EVENTS
        this.events = {
            'click': {
                'item': '_goTo'
            }
        }
    }

    init() {}

    _goTo(event) {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: document.querySelector('.' + event.currentTarget.dataset.anchorid).offsetTop - 50,
                autoKill: false
            },
            ease: 'power4.inOut'
        })
    }
}
