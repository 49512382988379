import { module } from 'modujs';
import Plyr from 'plyr';

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.player = null;
    }

    init() {
        this.player = new Plyr(this.el, {
            controls: ['play-large', 'progress']
        });
    }   
}
