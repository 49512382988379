import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
        this.dom.menu = document.querySelector('#menu-mobile');
        this.dom.back = this.dom.menu.querySelector('.back');

        // EVENTS
        this.events = {
            'click' : 'open'
        }
    }

    init() {
        console.log('INIT');
        this.dom.back.addEventListener('click', this.hide.bind(this));
    }

    reset() {
        gsap.set(this.dom.menu, {
            x: '100%'
        });
    }

    hide() {
        const _tl = gsap.timeline();
        _tl.to(this.dom.menu, {
            duration: 0.8,
            x: '100%',
            ease: 'power4.in'
        });
    }

    open() {
        console.log('IOEN ');
        const _tl = gsap.timeline();
        _tl.to(this.dom.menu, {
            duration: 0.8,
            x: 0,
            ease: 'power4.out'
        });
    }
}
