import { module } from 'modujs';
import gsap from "gsap";
import {SplitText} from "gsap/SplitText";
import {StoreSingleton} from "../../singletons/store";

gsap.registerPlugin(SplitText);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        // Split chars
        this._splitChars();

        // Events
        if(!StoreSingleton.isMobile) {
            for(let i = 0; i < this.$('item').length; i++) {
                this.$('item')[i].addEventListener('mouseenter', () => {
                    this.$('item')[i].classList.add('current');

                    const _tl = gsap.timeline({
                        defaults: {overwrite: true}
                    });
                    _tl.to(this.$('item')[i].querySelector('.over'), {
                        duration: 0.8,
                        alpha: 1,
                        ease: 'power4.out'
                    })
                    _tl.to(this.el.querySelectorAll('.offer-item:not(.current) .name'), {
                        duration: 1,
                        backgroundColor: '#FFFFFF',
                        ease: 'power4.out'
                    }, 0)
                });

                this.$('item')[i].addEventListener('mouseleave', () => {
                    this.$('item')[i].classList.remove('current');

                    const _tl = gsap.timeline({
                        defaults: {overwrite: true}
                    });
                    _tl.to(this.$('item')[i].querySelector('.over'), {
                        duration: 0.8,
                        alpha: 0,
                        ease: 'power4.out'
                    })
                    _tl.to(this.el.querySelectorAll('.offer-item:not(.current) .name'), {
                        duration: 1,
                        backgroundColor: 'transparent',
                        ease: 'power4.out'
                    }, 0)
                });
            }
        }
    }

    /**
     * SPLIT CHARS
     * @private
     */
    _splitChars() {
        new SplitText(this.$('chars'), {type: 'chars', charsClass: 'char char++'});
    }
}
