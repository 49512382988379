import { module } from 'modujs';
import Glide from '@glidejs/glide'

export default class extends module {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
        this.dom.related = this.el.querySelector('.glide');
        this.dom.prev = this.dom.related.querySelector('.glide__prev');
        this.dom.next = this.dom.related.querySelector('.glide__next');

        //VAR
        this.glide = null;

        // EVENTS
        this._prev = this._prev.bind(this);
        this._next = this._next.bind(this);
        this._resize = this._resize.bind(this);
    }

    /**
     * Init
     */
    init() {

        // GLIDE
        if(this.dom.related) {
            this.glide = new Glide(this.dom.related, {
                type: 'carousel',
                startAt: 0,
                perView: 1,
                gap: 130
            }).mount();

            this.dom.prev.addEventListener('click', this._prev);
            this.dom.next.addEventListener('click', this._next);
            window.addEventListener('resize', this._resize);
        }

        // READY
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * Start
     */
    start() {

    }

    /**
     * Destroy
     */
    destroy() {
        if(this.dom.related) {
            this.dom.prev.removeEventListener('click', this._prev);
            this.dom.next.removeEventListener('click', this._next);
            window.removeEventListener('resize', this._resize);
        }
    }

    /**
     * Resize
     * @private
     */
    _resize() {
        const _gap = window.innerWidth * 0.06;
        this.glide.update({
            gap: _gap
        });
    }

    _prev() {
        this.glide.go('<');
    }

    _next() {
        this.glide.go('>');
    }
}
