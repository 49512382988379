import { module } from 'modujs';
import gsap from 'gsap';
import { cross } from "../../../utils/environment";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.index = 0;
        this.timeline = null;
        this.timeout = null;
        this.time = parseInt(this.getData('time'));
        this.current = null;
        this.prev = null;
    }

    init() {

    }

    /**
     * Start
     */
    start() {

        // Current
        this.current = this.$('item')[this.index];

        /*gsap.set(cross, {
            fill: this.current.dataset.color,
        });*/

        // Init
        this.timeline = gsap.timeline({
            onComplete: () => {
                this.timeout = setTimeout(() => {
                    this._slideTo();
                }, this.time)
            }
        });
        this.timeline.to(this.current.querySelectorAll('.title h1'), {
            duration: 1.2,
            autoAlpha: 1,
            y: 0,
            rotation: 0.1,
            ease: 'power4.out'
        }, 0);
        this.timeline.to(this.current.querySelector('.title .button-rounded'), {
            duration: 0.8,
            autoAlpha: 1,
            y: 0,
            rotation: 0.1,
            ease: 'power4.out'
        }, 0.1);

    }

    /**
     * Destroy
     */
    destroy() {
        this.timeline.kill();
        clearTimeout(this.timeout);
    }

    /**
     * Build
     * @private
     */
    _slideTo() {

        if(this.index < this.$('item').length - 1)
            this.index++;
        else
            this.index = 0;

        if(this.current)
            this.prev = this.current;
        this.current = this.$('item')[this.index];

        this.timeline = gsap.timeline({
            onComplete: () => {
                this.timeout = setTimeout(() => {
                    this._slideTo();
                }, this.time)
            }
        });

        // SET CURRENT
        if(this.prev)
            this.prev.style.zIndex = 1;

        this.timeline.set(this.current.querySelector('.background-color'), {
            scaleX: 0
        });
        this.timeline.set(this.current.querySelector('.background img'), {
            autoAlpha: 0
        });
        this.timeline.set(this.current.querySelectorAll('.title h1'), {
            autoAlpha: 0,
            y: 20
        });
        this.timeline.set(this.current.querySelector('.title .button-rounded'), {
            autoAlpha: 0,
            y: 20
        });
        this.timeline.set(this.current, {
            autoAlpha: 1,
            zIndex: 10
        });

        this.timeline.to(this.current.querySelector('.background-color'), {
            duration: 1.4,
            scaleX: 1,
            ease: 'power4.inOut',
            onComplete: () => {
                if(this.prev) {
                    gsap.set(this.prev, {
                        autoAlpha: 0
                    });
                }
            }
        }, 0);
        this.timeline.to(this.current.querySelector('.background img'), {
            duration: 1.2,
            autoAlpha: 1,
            ease: 'power4.out'
        }, 1);
        /*this.timeline.to(cross, {
            duration: 1.2,
            fill: this.current.dataset.color,
            ease: 'power4.out'
        }, 1);*/
        this.timeline.to(this.current.querySelectorAll('.title h1'), {
            duration: 1.2,
            autoAlpha: 1,
            y: 0,
            rotation: 0.1,
            ease: 'power4.out'
        }, 1.2);
        this.timeline.to(this.current.querySelector('.title .button-rounded'), {
            duration: 0.8,
            autoAlpha: 1,
            y: 0,
            rotation: 0.1,
            ease: 'power4.out'
        }, 1.3);

    }
}
