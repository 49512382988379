import { html } from "./utils/environment";
import device from "current-device";
import modular from 'modujs';
import * as modules from './modules';
import {StoreSingleton} from "./singletons/store";
import gsap from 'gsap';

export default class App {
    constructor() {

        // DETECT SAFARI
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {document.querySelector('html').classList.add('is-safari')}

        // MOBILE
        if(device.mobile() ||  window.innerWidth <= 1023) {
            html.classList.add('is-mobile');
            StoreSingleton.isMobile = true;
        }

        // TABLET
        if(device.tablet())
            StoreSingleton.isTablet = true;

        // FUNC
        this.resize = this.resize.bind(this);

        // INIT
        this.init();
    }

    /**
     * INIT
     */
    init() {
        // HTML
        html.classList.remove('is-preloading');

        // TIMELINE
        const _tlIntroduction = gsap.timeline({
            onComplete: () => {
                this.createApp();
            }
        });
        _tlIntroduction.to('#preload-logo-1', {
            duration: 1.4,
            alpha: 1,
            y: 0,
            ease: 'power4.out'
        });
        _tlIntroduction.to('#preload-logo-2', {
            duration: 1.4,
            alpha: 1,
            y: 0,
            ease: 'power4.out'
        }, 0.1);
    }

    /**
     * Create app
     */
    createApp() {
        document.documentElement.scrollTop = 0;
        window.deadwater = {};

        // MODULES
        window.deadwater.app = new modular({
            modules: modules
        });
        window.deadwater.app.init(window.deadwater.app);

        // EVENTS
        window.addEventListener('resize', this.resize.bind(this), {passive: true});
        this.resize();

        setTimeout(() => {
            html.classList.add('first-load');
        }, 100);
    }

    /**
     * RESIZE
     */
    resize() {
        const _wWidth = window.innerWidth;

        if(_wWidth <= 1023 && !StoreSingleton.isMobile) {
            window.location.reload();
        } else if(_wWidth > 1023 && StoreSingleton.isMobile) {
            window.location.reload();
        }

        // VH
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}

/**
 * ON LOAD
 * @private
 */
const __dcl = () => {
    new App();
};

document.addEventListener("DOMContentLoaded", __dcl);
