import { module } from 'modujs';
import gsap from 'gsap';
import {StoreSingleton} from "../../../singletons/store";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.step = 0;
        this.length = this.$('item').length;
        this.isScrolling = false;
        this.bullet = this.$('bullet')[0];

        // EVENTS
        if(!StoreSingleton.isMobile) {
            this.events = {
                'click': {
                    'anchor': '_goTo',
                    'step-link': '_goTo'
                }
            }
        }

        // FUNC
        this.wheel = this.wheel.bind(this);
        this.swipe = this.swipe.bind(this);
        this.resize = this.resize.bind(this);
    }

    init() {

        // EVENTS
        if(!StoreSingleton.isMobile) {
            this.bind();
            window.addEventListener('resize', this.resize, {passive: true});

            // SWIPE
            this.el.addEventListener('swiped', this.swipe);
        }

        // READY
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * START
     */
    start() {}


    bind() {
        if(!StoreSingleton.isMobile) {
            this.el.addEventListener('mousewheel', this.wheel, {passive: true});
            this.el.addEventListener('DOMMouseScroll', this.wheel, {passive: true});
        }
    }

    unbind() {
        if(!StoreSingleton.isMobile) {
            this.el.removeEventListener('mousewheel', this.wheel);
            this.el.removeEventListener('DOMMouseScroll', this.wheel);
        }
    }

    /**
     * DESTROY
     */
    destroy() {
        if(!StoreSingleton.isMobile) {
            this.unbind();
            this.el.removeEventListener('swiped', this.swipe);
            window.removeEventListener('resize', this.resize);
        }
    }

    /**
     * WHEEL
     */
    wheel(event) {

        if(!this.isScrolling) {

            // RESET
            this.unbind()

            this.isScrolling = true;
            const _wHeight = window.innerHeight;
            const _direction = event.deltaY > 0 ? -1 : 1;
            if(_direction === -1) {
                if(this.step < this.length - 1)
                    this.step++;
            }
            else {
                if(this.step > 0)
                    this.step--;
            }
            if(this.bullet)
                this.bullet.classList.remove('current');
            const _tl = gsap.timeline({
                onComplete: () => {
                    this.bullet = this.$('bullet')[this.step];
                    if(this.bullet)
                        this.bullet.classList.add('current');
                    this.isScrolling = false;
                    this.bind();
                }
            });
            _tl.to(this.$('wrapper')[0], {
                duration: 1,
                y: -(this.step * _wHeight),
                ease: 'power4.inOut'
            });
            if(this.step === 1) {
                _tl.to(this.$('rect')[0], {
                    duration: 1,
                    y: 0,
                    ease: 'power4.inOut'
                }, 0);
            }
            if(this.step === 0) {
                _tl.to(this.$('rect')[0], {
                    duration: 1,
                    y: '100%',
                    ease: 'power4.inOut'
                }, 0);
            }
        }
    }

    swipe(event) {

        if(!this.isScrolling) {

            // RESET
            this.unbind()

            this.isScrolling = true;
            const _wHeight = window.innerHeight;
            const _direction = event.detail.dir === 'up' ? -1 : 1;
            if(_direction === -1) {
                if(this.step < this.length - 1)
                    this.step++;
            }
            else {
                if(this.step > 0)
                    this.step--;
            }
            if(this.bullet)
                this.bullet.classList.remove('current');
            const _tl = gsap.timeline({
                onComplete: () => {
                    this.bullet = this.$('bullet')[this.step];
                    if(this.bullet)
                        this.bullet.classList.add('current');
                    this.isScrolling = false;
                    this.bind();
                }
            });
            _tl.to(this.$('wrapper')[0], {
                duration: 1,
                y: -(this.step * _wHeight),
                ease: 'power4.inOut'
            });
            if(this.step === 1) {
                _tl.to(this.$('rect')[0], {
                    duration: 1,
                    y: 0,
                    ease: 'power4.inOut'
                }, 0);
            }
            if(this.step === 0) {
                _tl.to(this.$('rect')[0], {
                    duration: 1,
                    y: '100%',
                    ease: 'power4.inOut'
                }, 0);
            }
        }
    }

    /**
     * RESIZE
     */
    resize() {
        const _wHeight = window.innerHeight;
        gsap.set(this.$('wrapper')[0], {
            y: -(this.step * _wHeight),
        });
    }

    /**
     * Go to
     * @private
     */
    _goTo(event) {
        if(!this.isScrolling) {

            this.unbind();

            const _wHeight = window.innerHeight;
            const _step = parseInt(event.currentTarget.dataset.step);
            this.step = _step;

            if(this.bullet)
                this.bullet.classList.remove('current');

            const _tl = gsap.timeline({
                onComplete: () => {
                    this.bullet = this.$('bullet')[this.step];
                    if(this.bullet)
                        this.bullet.classList.add('current');
                    this.isScrolling = false;
                    this.bind();

                    if(this.step > 1) {
                        gsap.set(this.$('rect')[0], {
                            y: 0
                        });
                    }
                }
            });
            _tl.to(this.$('wrapper')[0], {
                duration: 1,
                y: -(_step * _wHeight),
                ease: 'power4.inOut'
            });
            if(_step === 1) {
                _tl.to(this.$('rect')[0], {
                    duration: 1,
                    y: 0,
                    ease: 'power4.inOut'
                }, 0);
            }
            if(_step === 0) {
                _tl.to(this.$('rect')[0], {
                    duration: 1,
                    y: '100%',
                    ease: 'power4.inOut'
                }, 0);
            }
        }
    }
}
