import { module } from 'modujs';
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default class extends module {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            'click': '_goUp'
        }

        // FUNC
        this.scroll = this.scroll.bind(this);
    }

    init() {
        window.addEventListener('scroll', this.scroll);
    }

    /**
     * SCROLL
     */
    scroll() {
        const _wScrollY = window.scrollY;
        if(_wScrollY > window.innerHeight + 200 && !this.el.classList.contains('hide')) {
            this.el.classList.add('show');
        } else {
            this.el.classList.remove('show');
        }
    }

    /**
     * GO UP
     * @private
     */
    _goUp() {
        gsap.to(window, {
            duration: 1,
            scrollTo: {y: 0},
            ease: 'power4.inOut'
        });
    }
}
