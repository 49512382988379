import { module } from 'modujs';
import axios from 'axios';
import Qs from 'qs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.isProcessing = false;
        this.isFirstLoad = true;
        this.filters = [];

        // FUNC
        this._openMobileFilters = this._openMobileFilters.bind(this);
        this._loadProjects = this._loadProjects.bind(this);
    }

    init() {

        this.$('mobile')[0].addEventListener('click', this._openMobileFilters);

        // EVENT
        this.$('filter').forEach((value) => {
            value.addEventListener('click', (event) => {
                if(!this.isProcessing) {
                    if(value.classList.contains('reset-all')) {
                        document.querySelectorAll('.filters a:not(.reset-all)').forEach(item => {item.classList.remove('selected')});
                        document.querySelectorAll('.filters a.reset-all').forEach(item => {item.classList.add('selected')});
                        this.filters = [];
                    } else {
                        if(!value.classList.contains('selected')) {
                            document.querySelectorAll('.filters a.reset-all').forEach(item => {item.classList.remove('selected')});
                            value.classList.add('selected');
                            this.filters.push(value.dataset.categoryid);
                        } else {
                            value.classList.remove('selected');
                            const index = this.filters.indexOf(value.dataset.categoryid);
                            this.filters.splice(index, 1);
                        }

                        if(this.filters.length === 0)
                            document.querySelectorAll('.filters a.reset-all').forEach(item => {item.classList.add('selected')});
                    }

                    this._loadProjects();
                }
            });
        });

        // READY
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * Start
     */
    start() {
        this._loadProjects();
    }

    destroy() {
        this.$('mobile')[0].removeEventListener('click', this._openMobileFilters);
    }

    /**
     * Open mobile filters
     * @private
     */
    _openMobileFilters() {
        if(!this.$('mobile')[0].classList.contains('open')) {
            this.$('mobile')[0].classList.add('open');
            gsap.to(this.$('mobile-container')[0], {
                duration: 0.8,
                height: this.$('mobile-wrapper')[0].clientHeight + 10,
                ease: 'power4.inOut',
                overwrite: true
            });
        } else {
            this.$('mobile')[0].classList.remove('open');
            gsap.to(this.$('mobile-container')[0], {
                duration: 0.4,
                height: 0,
                ease: 'power4.inOut',
                overwrite: true
            });
        }
    }

    /**
     * Load projects
     * @private
     */
    _loadProjects() {
        this.isProcessing  = true;

        const _tl = gsap.timeline({
            onComplete: () => {
                this._processLoading();
            }
        });
        if(!this.isFirstLoad) {
            _tl.to(this.$('wrapper')[0], {
                duration: 0.6,
                autoAlpha: 0,
                height: 0,
                ease: 'power4.out'
            });
        }
        _tl.to(this.$('loader')[0], {
            duration: 0.6,
            autoAlpha: 1,
            ease: 'power4.out'
        }, 0.5);
    }

    _processLoading() {
        let ajaxData = {
            action: 'ajax_function_manager',
            fn: 'getProjects',
            filters: this.filters,
        };

        axios({
            method:'get',
            url: AJAX_URL + '?' + Qs.stringify( ajaxData ),
            responseType: 'json'
        })
            .then((response) => {
                console.log('response : ' + response);
                this.$('container')[0].innerHTML = response.data.template;
                this.call('destroy', this.$('container')[0], 'app');
                this.call('update', this.$('container')[0], 'app');
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(() => {
                this.isProcessing = false;
                this.isFirstLoad = false;

                const _tl = gsap.timeline();
                _tl.to(this.$('loader')[0], {
                    duration: 0.6,
                    autoAlpha: 0,
                    ease: 'power4.out'
                });
                _tl.to(this.$('wrapper')[0], {
                    duration: 1,
                    autoAlpha: 1,
                    height: this.$('container')[0].clientHeight,
                    ease: 'power4.inOut',
                    onComplete: () => {
                        this.$('container')[0].style.height = 'auto';
                    }
                }, 0.3);
            });
    }
}
