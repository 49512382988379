import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        // Events
        for(let i = 0; i < this.$('item').length; i++) {
            this.$('item')[i].addEventListener('click', (event) => {
                this.$('item').forEach(elt => elt.classList.remove('current-menu-item', 'current_page_item'));
                event.currentTarget.classList.add('current-menu-item', 'current_page_item');
            })
        }
    }
}
