import {module} from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        // READY
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    start() {}
}
