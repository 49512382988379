import { module } from 'modujs';
import {StoreSingleton} from "../../../singletons/store";
import gsap from "gsap";

export default class extends module {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
    }

    /**
     * Init
     */
    init() {
        // READY
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * Start
     */
    start() {
        if(this.$('video')[0]) {
            if(!StoreSingleton.isMobile)
                this.$('video')[0].play();
        } else if(this.$('img-solo')[0]) {
            gsap.to('.r-image-solo h1, .r-image-solo .button-rounded', {
                duration: 0.6,
                autoAlpha: 1,
                y: 0,
                stagger: 0.1,
                ease: "power4.out"
            });
        } else {
            this.call('start', {}, 'Slider', 'HomeSlider');
        }
    }
}
