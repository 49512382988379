import { module } from 'modujs';
import gsap from "gsap";
import axios from "axios";
import Qs from "qs";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.isProcessing = false;
        this.isFirstLoad = true;
        this.filters = [];

        // FUNC
        this._loadProjects = this._loadProjects.bind(this);
    }

    init() {
        // READY
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * Start
     */
    start() {
        this._loadProjects();
    }

    /**
     * Load projects
     * @private
     */
    _loadProjects() {
        this.isProcessing  = true;

        const _tl = gsap.timeline({
            onComplete: () => {
                this._processLoading();
            }
        });
        if(!this.isFirstLoad) {
            _tl.to(this.$('wrapper')[0], {
                duration: 0.6,
                autoAlpha: 0,
                height: 0,
                ease: 'power4.out'
            });
        }
        _tl.to(this.$('loader')[0], {
            duration: 0.6,
            autoAlpha: 1,
            ease: 'power4.out'
        }, 0.5);
    }

    _processLoading() {
        let ajaxData = {
            action: 'ajax_function_manager',
            fn: 'getMedias',
            filters: this.filters,
            pageId: this.getData('id')
        };

        axios({
            method:'get',
            url: AJAX_URL + '?' + Qs.stringify( ajaxData ),
            responseType: 'json'
        })
            .then((response) => {
                console.log('response : ' + response);
                this.$('container')[0].innerHTML = response.data.template;
                //this.call('update', this.$('container')[0], 'app');
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(() => {
                this.isProcessing = false;
                this.isFirstLoad = false;

                const _tl = gsap.timeline();
                _tl.to(this.$('loader')[0], {
                    duration: 0.6,
                    autoAlpha: 0,
                    ease: 'power4.out'
                });
                _tl.to(this.$('wrapper')[0], {
                    duration: 1,
                    autoAlpha: 1,
                    height: this.$('container')[0].clientHeight,
                    ease: 'power4.inOut',
                    onComplete: () => {
                        this.$('container')[0].style.height = 'auto';
                    }
                }, 0.3);
            });
    }
}
