import { module } from 'modujs';
import gsap from "gsap";
import {StoreSingleton} from "../../singletons/store";

export default class extends module {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            'mouseenter': {
                'item': '_enter'
            },
            'mouseleave': {
                'item': '_leave'
            }
        }

    }

    init() {}

    _enter(event) {
        if(!StoreSingleton.isMobile) {
            event.currentTarget.classList.add('active');
            document.querySelectorAll('.r-1 .column-5:not(.active)').forEach(item => item.classList.add('hide'));
            document.querySelector('.background-container img.' + event.currentTarget.dataset.image).classList.add('active');
        }
    }

    _leave(event) {
        if(!StoreSingleton.isMobile) {
            event.currentTarget.classList.remove('active');
            document.querySelectorAll('.r-1 .column-5').forEach(item => item.classList.remove('hide'));
            document.querySelector('.background-container img.' + event.currentTarget.dataset.image).classList.remove('active');
        }
    }
}
