import { module } from 'modujs';
import gsap from 'gsap';
import {StoreSingleton} from "../../../singletons/store";
import Glide from "@glidejs/glide";

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        var url = window.location.href, idx = url.indexOf("#");
        var hash = idx != -1 ? url.substring(idx+1) : "";

        if(hash != "")
            gsap.to(window, {
                duration: 1,
                scrollTo: {y: '#' + hash, autoKill: false, offsetY: 50},
                ease: 'power4.out'
            });

        // MOBILE SLIDE
        if(StoreSingleton.isMobile) {
            new Glide(this.el.querySelector('.section-2 .glide'), {
                type: 'carousel',
                startAt: 0,
                perView: 1,
                gap: 18
            }).mount();
        }

        // READY
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    start() {}
}
